import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Ja ich weiß, das Thema Barrierefreiheit kann abschreckend wirken. Vor allem, wenn du den ersten Blick auf die Fülle
an `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/"
      }}>{`WCAG-Kriterien`}</a>{` und verfügbaren Ressourcen wirfst.`}</p>
    <p>{`Mein Rat: Erstmal tief durchatmen! Wähle ein Thema, das dich interessiert (z. B. semantisches Markup), und verbessere
zumindest eine Sache auf deiner Website oder App. Barrierefreiheit ist kein „Alles-oder-Nichts“-Thema!
Jede einzelne Verbesserung zählt.`}</p>
    <h2>{`Wo anfangen?`}</h2>
    <p>{`Hier ist eine Liste an Verbesserungen, die du zuerst bei deiner Website oder App vornehmen kannst:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Überprüfe den Farbkontrast von Text, grafischen Elementen und UI-Komponenten.`}</strong>{` Ein hoher Farbkontrast hilft vor allem älteren
Menschen, die `}<a parentName="p" {...{
            "href": "https://eldertech.org/color-in-designing-technology-for-seniors/"
          }}>{`Muster mit niedrigem Kontrast schlechter erkennen können`}</a>{`.
Beachte folgende WCAG-Kriterien: `}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html"
          }}>{`1.4.3 Kontrast (Minimum)`}</a>{` und
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html"
          }}>{`1.4.11: Nicht-Text-Kontrast`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Bediene deine Website nur mithilfe der Tastatur.`}</strong>{` Kannst du über die Tab-Taste zu allen interaktiven Elementen navigieren?
Prüfe, ob die Elemente mit dem richtigen semantischen Markup (z. B. Schaltflächen- und Link-Tags) implementiert wurden.
Navigiere auf dem Smartphone mit dem Screenreader per Wischbewegung und prüfe, ob interaktive Elemente richtig erkannt und aktiviert
werden können. Beachte folgende WCAG-Kriterien: `}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/keyboard.html"
          }}>{`2.1.1: Tastatur`}</a>{` and
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
          }}>{`4.1.2: Name, Rolle, Wert`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Haben deine Videos eine Untertitelspur?`}</strong>{` Untertitel ermöglichen gehörlosen und schwerhörigen Personen das Ansehen
von Videos. Gleichzeitig profitieren alle, die sich in einer Situation befinden, in der sie den Ton nicht anhören können
(z. B. in einer überfüllten U-Bahn ohne Kopfhörer). Beachte folgende WCAG-Kriterien:
`}<a parentName="p" {...{
            "href": "https://www.w3.org/WAI/WCAG21/Understanding/captions-prerecorded.html"
          }}>{`1.2.2: Untertitel (aufgezeichnet)`}</a></p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      